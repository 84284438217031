<template>
  <div class="leftTree">
    <div class="tree" v-if="leftNav.length == 0">
      <myScroll>
        <div class="tree-box">
          <el-tree
            ref="treeList"
            v-if="leftMenu"
            :data="leftMenu"
            node-key="id"
            icon-class=""
            :default-expanded-keys="expandedid"
            :current-node-key="currentid"
            @node-click="debounce"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :props="defaultProps"
          >
            <span
              class="slottree"
              slot-scope="{ node, data }"
              :class="{ notfolder: data.nodeType !== 'Folder', currentid: data.id == currentid && data.nodeType === 'Page' }"
            >
              <i :class="data.nodeType === 'Folder' ? 'el-icon-caret-right' : ''"></i><span>{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </myScroll>
    </div>
    <div v-if="leftNav.length > 0">
      <ul>
        <li v-for="(item, index) in leftNav" :key="index">{{ item.title }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { scenesData } from '@/datajs/static.js'

export default {
  props: ['dashboard', 'addDashboard'],
  data() {
    return {
      leftMenu: '',
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      expandedid: [],
      currentid: '',
      scenes: [],
      debounce: t => this.$commonJs2.debounce(this.handleClick(t), 2500),
      isClick: false,
    }
  },
  computed: {
    ...mapState({
      leftNav: state => state.leftNav,
      system: state => state.system,
    }),
  },
  watch: {
    'dashboard.length': function() {
      this.init()
      if (this.dashboard.length > 0) {
        this.leftMenu = this.dashboard
        let expandeds = this.dashboard.filter(x => {
          if (this.currentid) {
            let filters = x.children.filter(c => {
              return c.id == this.currentid
            })
            return x.id == this.currentid || filters.length > 0
          } else {
            return (x.children && x.children.length > 0) || x.nodeType === 'Page'
          }
        })
        if (expandeds.length > 0) {
          this.expandedid.push(expandeds[0].id)
        }

        this.$nextTick(function() {
          this.$refs.treeList.setCurrentKey(this.currentid)
        })
      }
    },
    '$route.hash': function() {
      if (this.isClick) return
      //路由变化 渲染新的页面
      this.initmenu()
    },
  },
  created() {
    this.scenes = scenesData
  },
  methods: {
    initmenu() {
      let hashid = this.$route.hash.replace('#/', '')
      let cur_menu = ''
      this.leftMenu.forEach(x => {
        if (x.nodeType === 'Folder' && x.children) {
          let menu = x.children.find(c => {
            return c.id.toString() == hashid
          })
          if (menu) {
            cur_menu = menu
          }
        } else {
          if (x.id.toString() == hashid) {
            cur_menu = x
          }
        }
      })
      this.debounce(cur_menu)
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.nodeType === 'Folder' || draggingNode.data.nodeType === 'Page') {
        if (draggingNode.data.nodeType === 'Folder' || dropNode.data.nodeType === 'Page') {
          return type !== 'inner'
        } else {
          return true
        }
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.name.indexOf('三级 3-2-2') === -1
    },
    handleClick(e) {
      // if (e.nodeType === 'Folder') {
      //   // this.$refs.tree.setCheckedNodes([e])
      // } else if (e.pageType == 1) {
      //   const url = this.$router.resolve({ name: e.pageHref })
      //   window.open(url.location.name, '_blank')
      // } else {
      //   this.$router.push({ path: this.$route.path + '#/' + e.id })
      //   this.$store.commit('setdashboardHash', e.id)
      //   this.currentid = e.id
      // }
      if (e.nodeType === 'Folder') {
        // this.$refs.tree.setCheckedNodes([e])
      } else if (e.isOutsideUrl) {
        const url = this.$router.resolve({ name: e.url })
        window.open(url.location.name, '_blank')
      } else {
        this.isClick = true
        if (e.id != this.$route.hash.replace('#/', '')) {
          this.$router.push({ path: this.$route.path + '#/' + e.id })
        }
        this.$store.commit('setdashboardHash', e.id)
        this.currentid = e.id
        setTimeout(() => {
          this.isClick = false
        }, 500)
      }
    },
    init() {
      this.currentid = this.$route.hash.replace('#/', '')
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.leftTree {
  .tree {
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    .tree-box {
      min-height: 100%;
      padding-bottom: 100px;
      position: relative;
    }
    .el-tree {
      padding-top: 10px;
      padding-left: 10px;
      @include theme_bg1($theme-light);
    }
    .addbtn {
      width: 250px;
      position: fixed;
      bottom: 0;
      left: 0;
      @include theme_bg1($theme-light);
      box-shadow: 0 0 8px 0 rgba(136, 152, 170, 0.15);
      padding: 20px;
      text-align: center;
      transition: 0.3s ease-in-out;
      .el-dropdown {
        width: 100px;
        .el-dropdown-link {
          display: block;
          border-radius: 22px;
          @include high_color1($highcolor-cheng);
          @include high_border1($highcolor-cheng);
          width: 100px;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
        }
      }
    }
  }
}

.openCard {
  .search-card {
    width: 300px;
  }
  .dialog-body {
    padding: 20px;
  }
  .dialog-footer {
    padding: 15px;
  }
  .cardlist {
    margin-top: 20px;
    .card-left {
      width: 300px;
      height: 316px;
      background: none;
      overflow: auto;
      @include theme_border_color($theme-border-light);
      border-radius: 4px;
    }
    .el-icon-sort {
      font-size: 20px;
      color: #adb5bd;
      transform: rotate(90deg);
      margin: 0 15px;
    }
    .card-right {
      width: 300px;
      height: 316px;
      border-radius: 4px;
      @include theme_border_color($theme-border-light);
      padding: 20px 10px;
      ul {
        width: 280px;
        height: 276px;
        overflow: auto;
        li {
          line-height: 26px;
          position: relative;
          padding: 0 20px 0 10px;
          b {
            font-weight: normal;
            color: #999;
            margin-left: 4px;
          }
          i {
            display: none;
            color: #999;
            position: absolute;
            top: 0;
            right: 5px;
            cursor: pointer;
          }
          &:hover {
            @include high_color1($highcolor-cheng);
            i {
              display: block;
            }
          }
        }
      }
    }
  }
}
.dialogfile {
  .el-dialog {
    .el-dialog__body {
      .el-form {
        margin-bottom: 50px;
        .el-form-item {
          margin-bottom: 10px;
          .el-radio {
            @include theme_color2($theme-dark);
          }
          .urllink {
            margin-top: 10px;
            @include theme_border_color($theme-border-light);
            min-height: 60px;
            max-height: 100px;
            overflow: auto;
          }
        }
        .el-input {
          width: 300px;
        }
      }
    }
  }
}

.configdashboard {
  .dialog-body {
    .el-form {
      .el-form-item {
        margin-bottom: 10px;
        .el-input {
          width: 300px;
        }
        .el-select {
          width: 300px;
        }
      }
    }
  }
  .dialog-footer {
    margin-top: 50px;
  }
}
</style>

<style lang="scss">
.leftTree {
  .el-tree {
    .el-tree-node {
      .el-tree-node__content {
        height: 34px;
        line-height: 34px;
        background: none;
        .el-tree-node__expand-icon {
          display: none;
        }
      }
      // &.is-current{
      //   .el-tree-node__content{
      //     .slottree{
      //       &.notfolder{
      //         span{
      //           font-size: 14px;
      //           @include high_color1($highcolor-cheng);
      //           font-weight: bold;

      //         }
      //       }

      //     }
      //   }
      // }
      .currentid {
        span {
          font-size: 14px;
          @include high_color1($highcolor-cheng);
          font-weight: bold;
        }
      }
      &.is-expanded {
        .el-tree-node__content {
          .slottree {
            .el-icon-caret-right:before {
              content: '\e790';
            }
          }
        }
      }
    }
    .el-tree-node__children {
      .el-tree-node {
        .el-tree-node__content {
          height: 34px;
          line-height: 34px;
          &:hover,
          &:focus {
            @include high_color1($highcolor-cheng);
            .el-tree-node__label {
              @include high_color1($highcolor-cheng);
            }
          }
          .el-tree-node__label {
            @include theme_color2($theme-light);
            font-weight: normal;
          }
        }
        &.is-current,
        &.is-checked {
          .el-tree-node__content {
            .el-tree-node__label {
              @include high_color1($highcolor-cheng);
            }
          }
        }
      }
    }
    .slottree {
      width: 100%;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: calc(100% - 40px);
        font-size: 14px;
        @include theme_color2($theme-light);
        font-weight: bold;
      }
      i {
        display: inline-block;
        width: 24px;
        padding: 6px;
        font-size: 14px;
        color: #d1d1d1;
      }
      &.notfolder {
        span {
          font-weight: normal;
          max-width: 190px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.cardtree {
  background: none !important;
  @include theme_color2($theme-dark);
  > .el-tree-node {
    &:focus > .el-tree-node__content {
      background: none;
    }
    > .el-tree-node__content {
      background: none;
      &:hover {
        // @include high_color1($highcolor-cheng);
        background: none;
      }
    }

    [aria-disabled='true'].el-tree-node {
      > .el-tree-node__content {
        @include high_color1($highcolor-cheng);
      }
    }
    .el-tree-node {
      > .el-tree-node__content {
        background: none;
        &:hover {
          @include high_color1($highcolor-cheng);
          background: none;
        }
      }
    }
  }
}
.editMenu {
  position: fixed;
  left: 120px;
  width: 120px;
  padding: 10px;
  z-index: 9;
  border-radius: 6px;
  @include theme_bg1($theme-light);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px 0 rgba(136, 152, 170, 0.15);
  ul {
    li {
      font-size: 14px;
      @include theme_color2($theme-light);
      cursor: pointer;
      line-height: 24px;
      display: flex;
      align-items: center;
      i {
        color: #adb5bd;
        font-size: 12px;
        margin-right: 5px;
      }
      &:hover {
        @include high_color1($highcolor-cheng);
      }
    }
  }
}
.dialogfile {
  .el-dialog {
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-form-item__label {
            line-height: 40px;
          }
          .el-form-item__content {
            line-height: 40px;
          }
          .el-textarea {
            min-height: 60px;
            font-size: 12px;
            textarea {
              min-height: 60px !important;
              background: none;
              border: none;
              resize: none;
              @include theme_color2($theme-dark);
            }
          }
        }
      }
    }
  }
}
</style>
