export const scenesData = [
  { value: null, label: '通用' },
  { value: 'groupon_name', label: '团购', name: '团购', defines: [{ target: 'User', code: 'page_refname' }] },
  { value: 'activity_name', label: '活动', name: '活动', defines: [{ target: 'User', code: 'page_refname' }] },
  {
    value: 'coupon_name',
    label: '卡券',
    name: '卡券',
    defines: [
      { target: 'User', code: 'coupon_name' },
      { target: 'User', code: 'coupon_name_extend', isExtend: true },
    ],
  },
  { value: 'movie_name', label: '线上电影购票', name: '电影票', defines: [{ target: 'User', code: 'page_refname' }] },

  {
    value: '_floor_name',
    label: '楼层筛选',
    name: '楼层',
    defines: [
      { target: 'Shop', code: '_floor_name' },
      { target: 'User', code: 'floor_name' },
      { target: 'User', code: 'shop_floorname', isExtend: true },
    ],
  },
  {
    value: '_commercial_type',
    label: '业态筛选',
    name: '业态',
    defines: [
      { target: 'Shop', code: '_commercial_type', dataType: 'String' },
      { target: 'User', code: 'commercialtypename', dataType: 'String' },
      { target: 'User', code: 'shop_commercialtypename', isExtend: true, dataType: 'String' },
    ],
  },
  {
    value: '_shop_name',
    label: '店铺筛选',
    name: '店铺',
    defines: [
      { target: 'Shop', code: '_shop_name' },
      { target: 'User', code: 'shop_shopname' },
    ],
  },
]

export const allColor = {
  color0: [
    ['#3AA0FF', '#45BB44', '#FACC15', '#FF6054', '#3DC2C2', '#FF8500', '#8543E0', '#5B8EE6', '#9ADE62', '#D141D9', '#3D3FCD', '#A080E6', '#DDDDDD'],
    ['#0B2766', '#143A8C', '#1D50B3', '#286DD9', '#3490FF', '#40A9FF', '#69C0FF', '#91D5FF', '#BAE7FF ', '#E6F7FF'],
  ],
  color1: [
    ['#FF8500', '#81A650', '#F78CB2', '#505BDA', '#C36DCC', '#F1655A', '#76A7F5', '#53C4E8', '#A998CA', '#EDC160', '#8FCAD6', '#85CF37', '#DDDDDD'],
    ['#613400', '#874D00', '#AD6800', '#D47105', '#FF8500', '#FFA400', '#FBD667', '#FCE58F', '#FDF1B8', '#FEFBE6'],
  ],
  color2: [
    ['#5CDBD3', '#FF94FA', '#FAF464', '#94DBFF', '#939CFF', '#FF7D72', '#7DAFFF', '#B0DD73', '#FFA4C4', '#8EE1F2', '#9BE94A', '#C48EFF', '#DDDDDD'],
    ['#00232A', '#00474F', '#006D75', '#08979C', '#12C2C1', '#36CFC9', '#5CDBD3', '#87E8DE', '#B5F5EC', '#E6FFFB'],
  ],
  color3: [
    ['#F759AB', '#67C2E3', '#C4E272', '#3AA0FF', '#505BDA', '#F1655A', '#76A7F5', '#81A650', '#F78CB2', '#8FCAD6', '#85CF37', '#B37FEB', '#DDDDDD'],
    ['#520239', '#780650', '#9E1068', '#C41D7F', '#EB2F96', '#F759AB', '#FF85C0', '#FFADD2', '#FFD6E7', '#FFF0F6'],
  ],
  color4: [
    ['#B37FEB', '#F1655A', '#EDC160', '#53C4E8', '#505BDA', '#F1655A', '#76A7F5', '#81A650', '#F78CB2', '#8FCAD6', '#85CF37', '#3AA0FF', '#DDDDDD'],
    ['#120337', '#22065E', '#391085', '#531DAB', '#722ED1', '#9255DE', '#B37FEB', '#D3ADF7', '#EFDBFF', '#F9F0FF'],
  ],
}

export const analysisData = [
  {
    category: 'behavior',
    name: '事件分析',
    content: '基于事件的指标统计、属性分组、条件筛选等功能的查询分析',
  },
  {
    category: 'attribute',
    name: '属性分析',
    content: '基于用户、店铺或其它实体属性进行的统计分析',
  },
  {
    category: 'segment',
    name: '分群分析',
    content: '依据用户、店铺等实体属性和行为特征进行筛选，对其进行观察和分析',
  },
  {
    category: 'template',
    name: '模板分析',
    content: '除上述常用分型模型外，其它分析模型（如漏斗、留存、路径等）均可通过模板分析实现',
  },
  {
    category: 'advance',
    name: '聚合分析',
    content: '基于已有卡片实现多表拼接，满足商场多表拼接的数据分析',
    tips: '即原来的高级分析',
  },
  {
    category: 'combinedList',
    name: '卡片组合',
    content: '基于已有卡片实现多卡片组合，最终在数据面板中主题模块展示',
  },
]
