<template>
  <div
    class="layout"
    :class="{ closeLeft: closeLeft }"
    v-cloak
    v-loading.fullscreen.lock="fullLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <headerComponent v-if="headInfo" class="layout-head" :headdata="headInfo" @getInfo="getLeftStatus"></headerComponent>
    <div class="page-main">
      <leftNav class="page-l" :dashboard="leftInfo" :addDashboard="isAddDashboard" @getChangeDash="getChange"></leftNav>
      <div class="page-r" :class="{ hasnews: news && news.isShow && news.detailUrl }">
        <news v-if="basicInfo && projectID && system == 'me'" system="me"></news>
        <router-view class="content" :sdata="closeLeft" :addDashboard="isAddDashboard"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import headerComponent from '@/components/headerComponent'
import leftNav from './leftNav'
import news from '@/components/news.vue'
import BI_config from '@/utils/config'

import { getSharedDashboards } from '@/api/apiV2_dashboard'

export default {
  name: 'layout',
  data() {
    return {
      theme: '',
      highColor: '',
      closeLeft: false, //菜单开关
      changeDashboard: '', //添加卡片的仪表盘
      leftInfo: null,
      headInfo: null,
      fullLoading: false,
      isAddDashboard: false,
    }
  },

  components: {
    headerComponent,
    leftNav,
    news,
  },
  computed: {
    ...mapState({
      menus: state => state.menus,
      projectID: state => state.projectID,
      system: state => state.system,
      basicInfo: state => state.basicInfo,
      news: state => state.news,
    }),
  },
  created() {
    this.getLoad()
  },
  methods: {
    async getLoad() {
      getSharedDashboards(this.system).then(v => {
        if (!v.data) return
        if (!v.data.hasMpPermission) {
          this.$router.push({ path: `${BI_config.dashboardProxy}/404` })
        }
        this.leftInfo = v.data.dashboardMenus
        let info = {
          custom: null,
          menus: null,
        }
        this.headInfo = info
        this.$store.commit('setDashboards', this.leftInfo)
        this.toFirstDashboard()
      })
    },
    toFirstDashboard() {
      if (!this.$route.hash) {
        let leftInfo = this.leftInfo || []
        if (leftInfo.length == 0) {
          return false
        }
        let firstid
        for (const item of leftInfo) {
          if (!item.isOutsideUrl && item.nodeType === 'Page') {
            firstid = item
            break
          }
          if (item.nodeType == 'Folder') {
            firstid = item.children.find(x => !x.isOutsideUrl)
            if (firstid) break
          }
        }
        if (firstid) {
          let id = firstid.id
          this.$router.replace({ path: this.$route.path + '#/' + id })
        }
      }
    },

    getLeftStatus(e) {
      this.closeLeft = e.closeLeft
      this.headInfo = e
    },
    getChange(e) {
      this.isAddDashboard = e
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
.layout {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  min-height: 100%;
  .layout-head {
    width: 100%;
    min-width: 1000px;
    height: 60px;
    @include theme_bg1($theme-light);
    position: fixed;
    z-index: 99;
    transition: 0.3s ease-in-out;
  }
  .page-l {
    width: 250px;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    z-index: 99;
    @include theme_bg1($theme-light);
    transition: 0.3s ease-in-out;
  }
  .page-main {
    height: 100%;
    margin-left: 250px;
    transition: 0.3s ease-in-out;
    .page-r {
      padding: 0;
      padding-top: 80px;
      transition: 0.3s ease-in-out;
      height: 100%;
      &.hasnews {
        padding-top: 130px;
      }
      .content {
        transition: 0.3s ease-in-out;
        height: 100%;
        bottom: 0px;
        width: 100%;
        overflow-y: auto;
      }
    }
  }
  &.closeLeft {
    .headerBox {
      min-width: 1200px;
      .logo {
        margin-left: -250px;
        transition: 0.3s ease-in-out;
      }
      .logo-r {
        width: 100%;
        transition: 0.3s ease-in-out;
      }
    }
    .page-l {
      left: -250px;
      transition: 0.3s ease-in-out;
      .tree-box {
        .addbtn {
          left: -250px;
          transition: 0.3s ease-in-out;
        }
      }
    }
    .page-main {
      margin-left: 0;
      transition: 0.3s ease-in-out;
    }
    .page-r {
      width: 100%;
      transition: 0.3s ease-in-out;
      .news {
        width: calc(100% - 37px);
        transition: 0.3s ease-in-out;
      }
    }
  }
}
</style>
